define('m01new/routes/projektdaten', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Route.extend({
    model: function model() {
      return this.store.find("projektdaten");
    },

    setupController: function setupController() {
      this.controllerFor("application").set("currentPath", "projektdaten");
      this.controllerFor("application").miniertX3D();
      this.controllerFor("application").get("model").x3ddefault.objectAt(0).set("istDachkonstruktion", true);
    }
  });

});